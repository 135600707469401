import Image from "next/image";
import React, { useState, useEffect } from "react";
import Menu from "./Menu";
import styles from "styles/styles.json";
import NavBurger from "./NavBurger";
import { motion, AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import { Button, Show } from "@chakra-ui/react";
import Link from "next/link";
import { ProfileMenu } from "@/components/SearchBar";
import useCheckLogin from "@/lib/hooks/useCheckLogin";

type Window = {
  width: number;
  height: number;
};


function NavBar() {
  const [showPopup, setShowPopup] = useState(false);
  const router = useRouter();
  const { data: isLoggedIn } = useCheckLogin();
  const [windowSize, setWindowSize] = useState<Window>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window?.innerWidth,
        height: window?.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let navMenu = ["Store", "Biological Age", "Bonatra Smart Ring X1"];
  const isRingPreOrderPage = router.pathname === "/bonatra-ring-x1";
  const isCGMPage = router.pathname === "/continuous-glucose-monitoring";
  const isLandingPage = router.pathname === "/newlandingpage"

  const renderSecondMenuItem = (item, id) => id === 1 ? (
    <div
      key={item} className={`md:font-normal font-semibold w-28 md:w-36 md:gap-4 py-2 ml-[-20px] mr-[-16px] md:border md:border-solid md:border-black rounded-2xl text-center flex flex-col relative cursor-pointer px-0.5 ${(isCGMPage || isRingPreOrderPage) ? 'border-white' : 'border-black'}`}
    >
      <Link href="/biologicalAgeCalculator">
        <span className={`text-lg ${isRingPreOrderPage ? 'text-white' : ''}`}>{windowSize?.width < 760 ? 'Bio Age' : item}</span>
      </Link>
    </div>
  ) : null
  
  const renderFirstMenuItem = (item, id) => (id === 0) ? (
    <div key={item} className={`md:font-normal font-semibold ml-[-20px] md:w-36 md:gap-4 py-2 md:border md:border-solid md:border-black rounded-2xl cursor-pointer hidden md:flex relative md:justify-center px-[1rem] ${(isCGMPage || isRingPreOrderPage) ? 'border-white' : 'border-black'}`}>
      <Link href="/all-products">
        <span className={`text-lg ${isRingPreOrderPage ? 'text-white' : ''}`}> {item}</span>
      </Link>
    </div>
  ) : null

  const renderThirdMenuItem = (item, id) => id === 2 ? (
      <div key={item} className={`md:font-normal font-semibold py-2 w-28 md:w-60 md:border md:border-solid md:border-black rounded-2xl text-center cursor-pointer relative  ${windowSize?.width > 760 ? 'px-[1rem]' : 'px-0.5'} ${(isCGMPage || isRingPreOrderPage) ? 'border-white' : 'border-black'}`}>
        <Link href="/bonatra-ring-x1">
          <span className={`text-lg ${isRingPreOrderPage ? 'text-white' : ''}`}> {windowSize?.width < 760 ? 'Smart Ring' : item}</span>
        </Link>
      </div>
  ) : null


  return (
    <div className={`flex justify-center ${(isRingPreOrderPage || isCGMPage) && windowSize.width > 770 ? 'text-white' : 'text-black sticky top-0 left-0 z-[2000] '}`} >
      <section className={`px-8 pt-[2rem] pb-8 md:pb-4 max-w-screen-2xl mx-auto w-full  ${(isRingPreOrderPage || isCGMPage || isLandingPage) ? 'absolute z-50 mx-auto ' : 'bg-white  relative'}`}>
        <div className="h-10 flex mx-auto justify-between items-center ">
          <div className="flex flex-row justify-center items-center gap-12">
            <Link className="w-24 h-16 md:w-32 md:h-24 md:mr-16 relative flex md:flex" href="/">
              <Image
                src="https://bonatra-assets.s3.ap-south-1.amazonaws.com/images/bonatra-raush-logo.webp"
                className=""
                alt="bonatra - logo"
                fill
                priority
                style={{ objectFit: "contain" }}
                sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
              />
            </Link>
            <Link className="w-32 h-24 relative hidden md:hidden flex" href="/">
              <Image
                src="https://bonatra-assets.s3.ap-south-1.amazonaws.com/package_detail/Bonatra+Black.webp"
                className=""
                alt="bonatra - logo"
                fill
                priority
                style={{ objectFit: "contain" }}
                sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
              />
            </Link>
            <AnimatePresence mode="wait">
              <motion.div
                className={`${styles.flexCenter} flex-row gap-4 md:gap-16`}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                {
                  navMenu?.map((item, id) => {
                    return (
                      <React.Fragment key={item}>
                        {renderSecondMenuItem(item, id)}
                        {renderFirstMenuItem(item, id)}
                        {renderThirdMenuItem(item, id)}
                      </React.Fragment>
                    )
                  })
                }
              </motion.div>
            </AnimatePresence>
          </div>

          {isLoggedIn ? (

            <ProfileMenu />

          ) : (
            <div className={`${styles.flexCenter} flex-row gap-8 ${(isRingPreOrderPage || isCGMPage || isLandingPage) ? 'text-white' : 'text-black'}`}>
              {windowSize.width > 760 && (
                <>
                  <button
                    className="font-semibold"
                    onClick={() =>
                      router.push({
                        pathname: "/login",
                      })
                    }
                  >
                    Login
                  </button>
                  <Show above="md">
                    <Button
                      bg="black"
                      color="white"
                      _hover={{ bg: "gray.700" }}
                      onClick={() =>
                        router.push({
                          pathname: "/signup",
                        })
                      }
                    >Signup</Button>
                  </Show>

                </>
              )

              }
              <NavBurger />
            </div>
          )}

          <AnimatePresence>

            {showPopup && (
              <motion.div
                animate={{ opacity: [0, 1], y: [40, 0] }}
                exit={{ opacity: 0, y: 40 }}
                className="absolute left-0 top-[3.5rem] w-full z-[110] text-black"
              >
                <Menu />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </section>
    </ div>
  );
}

export default NavBar;