import {
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Icon,
  Flex,
  Link,
} from "@chakra-ui/react";
import { SearchIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { FiShoppingCart } from "react-icons/fi";
import { CgProfile } from "react-icons/cg"
import useZustandStore from "@/lib/store/useZustandStore";
import Pill from "./Pill";
import Image from "next/image";
import useSearchItem from "@/lib/hooks/QueryHooks/useSearchItem";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import NavBurger from "@/lib/Layout/NavBar/NavBurger";
import { motion } from "framer-motion";
import useGetAllCartItems from "@/lib/hooks/QueryHooks/useGetAllCartItems";
import { useState } from "react";
import useGetCartCount from "@/lib/hooks/useGetCartCount";
import { logEvent } from "@/analytics";
import useCheckLogin from "@/lib/hooks/useCheckLogin";

function SearchBar() {
  const [text, setText] = useState("");
  const productFilters = useZustandStore((state) => state.productFilters);
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const { data: allSearchItems } = useSearchItem(query);
  
  const debouncedSearch = debounce((newQuery) => {
    setQuery(newQuery);
  }, 500);

  const handleChange = (value: string | any[]) => {
    debouncedSearch(value);
  };

  return (
    <>
      <div className="flex flex-row items-center justify-center relative">
        <InputGroup
          gap="3"
          alignItems="center"
          justifyContent={"center"}
          className="z-30"
        >
          <InputLeftElement
            pointerEvents="none"
            // top={{ sm: "0px", md: "9px" }}
            // eslint-disable-next-line react/no-children-prop
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            value={text}
            placeholder="Search"
            boxShadow="base"
            border="none"
            focusBorderColor="none"
            onChange={(e) => {
              setText(e.target.value);
              setIsOpen(true);
              handleChange(e.target.value);
            }}
          />
        </InputGroup>
      </div>
      <div className="py-4 flex flex-wrap gap-4 items-center">
        <Pill
          key={"All Products"}
          selected={false}
          clickHandler={() => { }}
          isNotClickable={true}
        >
          {"All Products"}
        </Pill>
        <ChevronRightIcon color="black" />
        {productFilters.brand.map((filter: string) => (
          <Pill
            key={filter}
            selected={false}
            clickHandler={() => { }}
            isNotClickable={true}
          >
            {filter}
          </Pill>
        ))}
      </div>
      <div className="relative ">
        {isOpen && text.length > 0 && (
          <div className="absolute -top-12 w-full bg-white rounded-lg p-4 shadow-2xl z-[100] cursor-pointer">
            {allSearchItems?.message?.product_results?.map(
              (item: {
                web_item_name: string;
                brand: any;
                website_image: string;
                item: any;
                route: string;
                item_group: string;
              }) => {
                return (
                  <>
                    <SearchItem
                      item={item}
                      webItemName={item?.web_item_name}
                      brand={item?.brand}
                      websiteImage={item?.website_image}
                      route={item?.route}
                      item_group={item?.item_group}
                    />
                  </>
                );
              }
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default SearchBar;

export const ProfileMenu = (isRingSizer) => {
  const { cartQty, isLoading: cartQtyLoading } = useGetCartCount();
  const navigation = useRouter();
  const { data: isLoggedIn } = useCheckLogin();

  return (
    <Flex
      direction="row"
      className="gap-4"
      alignItems="center"
      justifyContent="space-between"
    >
      {/* <Link href="/bonatra-team">
        Team
      </Link> */}
      {!isRingSizer && <Link href="/bonatra-team">Team</Link>}
      {/* <Flex direction="column" justifyContent="center" alignItems="center">
        <IconButton
          aria-label="favourites"
          icon={<Icon as={FiHeart} w={5} h={6} />}
          variant="outline"
          border="none"
          onClick={() => setDrawerState("FAVOURITES")}
        />
        <Show above="md">
          <Text fontSize="xs" wordBreak="keep-all">
            Favourites
          </Text>
        </Show>
      </Flex> */}
      {isLoggedIn ? <CgProfile className="ml-1 hidden md:flex" style={{cursor: 'pointer'}} onClick={() => navigation.push('/profile')} size={23} color="grey" /> : null}
      <Flex marginLeft={10} className="" direction="column" justifyContent="center" alignItems="center">
        <IconButton
          className="ml-[-24px] mr-[-8px]"
          id="favourites_products"
          aria-label="favourites"
          icon={<Icon as={FiShoppingCart} w={5} h={6} />}
          variant="outline"
          border="none"
          onClick={() => {
            logEvent("Products", "Cart");
            (window as any).fbq('trackCustom', 'Cart');
            navigation.push("/cart")
          }}
        />

        {cartQty !== 0 && (
          <motion.div
            initial={{ y: -18, x: 2 }}
            className="absolute flex justify-center items-center  bg-primary p-1 h-6 w-6 text-xs rounded-full text-white font-bold"
          >
            {cartQty || "0"}
          </motion.div>
        )}

        {/* <Show above="md">
          <Text fontSize="xs" wordBreak="keep-all">
            Cart
          </Text>
        </Show> */}
      </Flex>


      {/* <Flex direction="column" justifyContent="center" alignItems="center" >
        <IconButton
          aria-label="favourites"
          icon={<Icon as={FiUser} w={5} h={6} />}
          variant="outline"
          border="none"
          onClick={() => setDrawerState("PROFILE")}
        />
        <Show above="md">
          <Text fontSize="xs" wordBreak="keep-all">
            Profile
          </Text>
        </Show>
      </Flex> */}

      <NavBurger />
    </Flex>
  );
};

const SearchItem = ({
  webItemName,
  brand,
  websiteImage,
  item,
  route,
  item_group,
}: {
  webItemName: string | number | null | undefined;
  brand: any;
  websiteImage: string;
  item: any;
  route: string;
  item_group: string;
}) => {
  const navigation = useRouter();
  return (
    <div
      className="mb-2 flex flex-row justify-start items-center gap-4 shadow-lg p-2"
      onClick={() => {
        if (item_group.toLowerCase() === "ring") {
          navigation.push({
            pathname: "/bonatra-ring-x1",
          });
        } else {
          navigation.push({
            pathname: route,
          });
        }
      }}
    >
      <div className="w-10 h-10 border-2 border-gray relative">
        <Image
          width={100}
          height={100}
          src={websiteImage != null ? websiteImage : ""}
          alt={brand}
          style={{ objectFit: "contain" }}
        />
      </div>
      <div>
        <h2 className="text-md font-bold mb-0">{webItemName}</h2>
        {brand && <h3 className="text-gray-400 text-sm">{`by ${brand}`}</h3>}
      </div>
    </div>
  );
};