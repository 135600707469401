import { motion } from 'framer-motion';
import React, { ReactNode } from 'react'

interface props {
    children: ReactNode;
    selected: boolean;
    clickHandler: () => void;
    isNotClickable?: boolean
}
const Pill: React.FC<props> = ({ children, selected = false, clickHandler, isNotClickable = false }) => {
    return (
        <motion.div
            onClick={clickHandler}
            className={`cursor-pointer p-2 text-xs px-4 rounded-3xl ${selected ? "bg-black text-white" : "bg-gray-200"}`}
            whileHover={{ filter: isNotClickable ? "brightness(1)" : "brightness(0.8)" }}
        >{children}
        </motion.div>
    )
}

export default Pill

