import { useQuery } from "@tanstack/react-query";
import axios from "axios";


const useSearchItem = (query: string) => {
  
    const url = `${process.env.NEXT_PUBLIC_API_URL}/api/method/erpnext.templates.pages.product_search.search?query=${query}`;
    return useQuery<any>(["getAllSearchItems",query], async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
      };
      if(query.length >= 3){
        const res = await axios(config);
        return res.data;
      }
     return null;
      
      
    });  
};

export default useSearchItem;
