import useZustandStore from "@/lib/store/useZustandStore";
import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import { useRouter } from 'next/router';
import useWindowSize from "@/lib/hooks/useWIndowSize";

const NavBurger = () => {
    return (
        <>
            <Toggle />
        </>
    );
};

export default NavBurger;

const Path = (props: any) => {
    const mode = props.mode
    const route = useRouter()
    const navBurgerState = useZustandStore((state) => state.navBurgerState);
    const isRingPreorderPage = route.pathname === '/bonatra-ring-x1'
    const size = useWindowSize()
    return <motion.path
        fill="transparent"
        strokeWidth="3"
        stroke={isRingPreorderPage ? !navBurgerState ? "#fff" : "#000" : "#000"}
        strokeLinecap="round"
        {...props}
    />
}

const Toggle = () => {
    const navBurgerState = useZustandStore((state) => state.navBurgerState);
    const setNavBurgerState = useZustandStore((state) => state.setNavBurgerState);
    //convert this state to global, dont render page if true, only render nav menu
    return (
        <motion.nav
            initial={false}
            animate={navBurgerState ? "show" : "hidden"}
            className="relative cursor-pointer z-[1050] "
        >
            <motion.div
                onClick={() => setNavBurgerState()}
                // className={styles.burgerCircle}
                style={{ zIndex: 100 }}
            >
                <svg width="23" height="23" viewBox="0 0 23 23" style={{ zIndex: 100 }}>
                    <Path
                        variants={{
                            hidden: { d: "M 2 2.5 L 20 2.5" },
                            show: { d: "M 3 16.5 L 17 2.5" },
                        }}
                    // animate={toggleOpen ? "show" : "hidden"}
                    />

                    <Path
                        d="M 2 9.423 L 20 9.423"
                        variants={{
                            hidden: { opacity: 1 },
                            show: { opacity: 0 },
                        }}
                        transition={{ duration: 0.1 }}
                    />
                    <Path
                        variants={{
                            hidden: { d: "M 2 16.346 L 20 16.346" },
                            show: { d: "M 3 2.5 L 17 16.346" },
                        }}
                    />
                </svg>
            </motion.div>
            <AnimatePresence>
                {navBurgerState && (
                    <motion.div initial="hidden" animate="show" exit="exit"></motion.div>
                )}
            </AnimatePresence>
        </motion.nav>
    );
};
